.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        @apply ring-0;
    }
}

.container {
    margin-left: theme("margin.auto");
    margin-right: theme("margin.auto");
    max-width: 995px;
    padding: 0 theme("spacing.4");
  }
