@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@900&family=Pacifico&display=swap');

@tailwind base;
@tailwind components;

// Additional styles
@import 'additional-styles/utility-patterns.scss';
@import 'additional-styles/range-slider.scss';
@import 'additional-styles/toggle-switch.scss';
@import 'additional-styles/flatpickr.scss';
@import 'additional-styles/theme.scss';


@tailwind utilities;

.leaflet-container {
    width: 100%;
    height: 100%;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.baseColor {
  background-color: #FBF6EA;
}

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
.logoFont {
  font-family: 'League Spartan', sans-serif;
}
